<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar
        title="社区活动"
      />
    </div> -->
    <!-- tab栏 -->
    <!-- <van-sticky>
      <van-tabs @click="onClickOnlyOne"
        v-if="TitleList.length == '1'"
        color="#31D984"
        title-active-color="#31D984">
        <van-tab v-for="item in TitleList[0].Children"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name">
        </van-tab>
      </van-tabs>
      <van-tabs @click="topClick"
        v-else
        color="#ed664e"
        title-active-color="#ed664e">
        <van-tab v-for="item in TitleList"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name">
          <van-tabs v-if="item.Children.length != '0'"
            v-model="activeName"
            @click="onClick"
            color="#31D984"
            title-active-color="#31D984">
            <van-tab v-for="item in item.Children"
              :key="item.AKId"
              :name="item.AKId"
              :title="item.Name">

            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </van-sticky> -->
    <van-sticky>
      <div>
        <!-- <van-search v-model="listfrom.akName"
          readonly
          placeholder="请选择活动分类"
          @search="onSearch"
          @click="ArtivityTypeshow = true">
          <template #left-icon>
            <img src="https://lyxnyapi.dexian.ren//upload/images/2022/7/cfe5e07a-158.png"
              alt=""
              width="14"
              height="14">
          </template>
        </van-search> -->
        <van-field v-model="listfrom.akName"
          is-link
          readonly
          placeholder="请选择活动分类"
          @click="ArtivityTypeshow = true" />
        <van-popup v-model="ArtivityTypeshow"
          round
          position="bottom">
          <van-cascader v-model="cascaderValue"
            title="请选择活动分类"
            :options="TitleList"
            @close="ArtivityTypeshow = false"
            :field-names="{
              text: 'Name',
              value: 'AKId',
              children: 'Children',
            }"
            @finish="onFinish" />
        </van-popup>
      </div>
    </van-sticky>
    <div class="information">
      <van-list v-model="loading"
        :finished="finished"
        offset="10"
        @load="onLoad">
        <!-- 全部 -->
        <div class="center borderClass"
          v-for="item in list"
          :key="item.index">
          <router-link :to="'/volunteerAct/detail/' + item.AId">

            <div class="Content">
              <div style="display: inline-block; width: 33%"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.AImgUrl"
                  style="border-radius:5px;"
                  :src="item.AImgUrl"
                  alt="" />
              </div>
              <div style="display: inline-block; width: 63%"
                class="ContentB">
                <div class="centerTitle van-ellipsis--l2">
                  {{ item.Title }}
                </div>
                <span class="centertime van-multi-ellipsis--l2"
                  style="margin:5px 0px;font-size: 13px;color: #666666;">{{ item.Outline }}</span>
              </div>
              <span class="centertime"
                style="float: right; margin-top: -15px;color:black;">{{
              item.AuditTime
            }}</span>
            </div>
          </router-link>
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">── 没有更多数据了 ──</div>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WePBGetActivityPage, WeGetPBActivityPage, WeGetActivityPage, WeGetMyActivityPage } from "@/api/RealInfo";
export default {
  data () {
    return {
      akId: 0, //tab栏 
      activeName: "",
      list: [], //资讯列表
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      listfrom: {
        page: 0,
        limit: 10,
        akId: undefined,
        akName: '',
        pAKind: 3,
      }, //查询传参
      hidden: true, //没有更多数据了
      TitleList: [],
      search: "", //搜索内容
      ArtivityTypeshow: false,
      cascaderValue: "",
    };
  },
  methods: {
    // 下拉加载更多
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // 社区搜索
    onFinish: function (val) {
      console.log(val);
      this.ArtivityTypeshow = false;
      this.fieldValue = val.selectedOptions.map((option) => option.Name).join("/");
      this.listfrom.akName = this.fieldValue;
      this.listfrom.akId = val.value
      this.list = []
      this.getList();
      // this.getSerOrganList()
      // this.getSerOrganCountSearch(this.listfrom.akId)
    },
    // 活动分类
    getPBActivityList: function () {
      WeGetPBActivityPage({ PAKind: 3, type: 1 }).then((res) => {
        this.TitleList = res.data.data;
        // if (this.TitleList[0].Children != '0') {
        //   this.listfrom.akId = this.TitleList[0].Children[0].AKId
        // } else {
        //   this.listfrom.akId = this.TitleList[0].AKId
        // }
        // this.getList();
      })
    },
    // 获取分页列表
    getList () {
      this.hidden = false
      WeGetMyActivityPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            // if (res.data.count == 0) {
            //   this.list = {};
            // } else {
            //   this.list = res.data.data;
            //   for (var i = 0; i < this.list.length; i++) {
            //     if (this.list[i].AImgUrl == "") {
            //       this.list[i].AImgUrl =
            //         "http://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
            //     }
            //   }
            // }
            // this.ServiceList = res.data.data; 
            let rows = res.data.data; //请求返回当页的列表
            console.log(rows);
            // 加载状态结束
            this.loading = false;

            if (res.data.code == 0) {
              if (rows == null || rows.length === 0) {
                // 加载结束
                console.log("结束");
                this.finished = true;
                this.hidden = true
                return;
              }
              // 将新数据与老数据进行合并
              this.list = this.list.concat(rows);

              //如果列表数据条数>=总条数，不再触发滚动加载
              if (this.list.length >= res.data.count) {
                this.finished = true;
                this.hidden = true
              }
            }
          }
        })
        .catch(() => { });
    },
    topClick: function (name, title) {
      for (let index = 0; index < this.TitleList.length; index++) {
        const element = this.TitleList[index];
        if (element.Children.length != '0') {
          if (element.AKId == name) {
            this.listfrom.akId = element.Children[0].AKId
            this.activeName = element.Children[0].AKId
            this.getList()
          } else {
            this.listfrom.akId = element.AKId
            this.getList()
          }
        }

      }
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.listfrom.Title = this.search;
      this.getList();
    },
    // tab点击切换事件
    onClick (name, title) {
      this.list = []
      this.listfrom.akId = name
      this.listfrom.page = 1
      this.getList()
    },
    onClickOnlyOne (name, title) {
      this.list = []
      this.listfrom.akId = name
      this.listfrom.page = 1
      this.getList()
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }

    this.getPBActivityList()
  },
};
</script>
<style scoped >
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}
</style>